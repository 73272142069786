
import dayjs from "dayjs";
import {
    Link,
    useParams,
    useNavigate,
    useSearchParams,
  } from "react-router-dom";
  import { Helmet } from "react-helmet";
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  TextField,
  TableFooter,
  TablePagination,
  Checkbox,
} from "@material-ui/core";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useState } from "react";

const ChartTab = ({events, setEventsOpen, eventsOpen, stats, handleCheckImportant}) => {
    const [statsOpen, setStatsOpen] = useState(true);
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
    
    let i = 0;
    const colors = [
      "#FF6384", 
      "#36A2EB", 
      "#FFCE56",
      "#4BC0C0", 
      "#9966FF", 
      "#FF9F40", 
      "#FF6384", 
      "#C9CBCF", 
      "#7ACBEE", 
      "#7EB86F", 
      "#B084CC",
      "#F7B801", 
      "#53A548", 
      "#404E7C", 
      "#D14545", 
      "#5CDBD3", 
      "#ED7D3A", 
      "#8E6E53", 
      "#77DD77", 
      "#779ECB" 
    ];
    const getColor = () => {
        i++
        return colors[i]
      }
    
      const labels = [...new Set(stats.flatMap(d => d.stats.map(stat => stat.date.slice(0, 10))))].sort();
      const datasets = stats.map(event => ({
        label: event.event_name,
        data: labels.map(label => {
          const entry = event.stats.find(stat => stat.date.slice(0, 10) === label);
          return entry ? entry.count : 0;
        }),
        borderColor: getColor(),
        backgroundColor: 'transparent',
      }));
    
      const chartData = {
        labels,
        datasets,
      };



    
    



return (    <> <Box sx={{ backgroundColor: "background.default", pt: 3, pb: 1 }}>
    <Box  className="headerWrapper">
        <Box sx={{ pb: 3 }} className="headerTitle">Управление событиями</Box>
      </Box>
    <Container maxWidth={false}>
    <Card >
    <CardContent sx={{ p: 3 }}>
      <Button onClick={() => setEventsOpen(!eventsOpen)}>{eventsOpen ? 'Свернуть' : 'Открыть'}</Button>
       {
          eventsOpen &&        <Table>
          <TableHead>
            
            <TableRow>
              <TableCell>
                Название события
              </TableCell>
         
              <TableCell>Важное</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {events?.map((ev) => (
              <TableRow hover key={ev.id}>
                <TableCell>
                  {ev.event_name ?? ev.name}
                </TableCell>
                
                <TableCell>
                <Checkbox checked={ev.isImportant} onClick={() => handleCheckImportant(ev.id, ev.name ? true : false)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
       }

                      </CardContent>
                      </Card>
                      </Container>
                      </Box>

                      <Box sx={{ backgroundColor: "background.default", pt: 3, pb: 1 }}>
    <Box  className="headerWrapper">
        <Box sx={{ pb: 3 }} className="headerTitle">Статистика по важным событиям</Box>

      </Box>
    <Container maxWidth={false}>
    <Card >
    <CardContent sx={{ p: 3 }}>
      <Button onClick={() => setStatsOpen(!statsOpen)}>{statsOpen ? 'Свернуть' : 'Открыть'}</Button>
       {
          statsOpen &&   <div>
<Line data={chartData} options={{ responsive: true, maintainAspectRatio: true }} />
          </div>
          
       }

                      </CardContent>
                      </Card>
                      </Container>
                      </Box></> )
}


export default ChartTab