import {Navigate} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from "./pages/Systems/Logs/Logs";
import EmailTemplateList from "./pages/Templates/Email-Templates/EmailTemplateList";
import EmailTemplateCreate from "./pages/Templates/Email-Templates/EmailTemplateCreate";
import EmailTemplateEdit from "./pages/Templates/Email-Templates/EmailTemplateEdit";
import CommonSettings from "./pages/Systems/CommonSettings/CommonSettings";
import UpdateSystem from "./pages/Systems/UpdateSystem/UpdateSystem";
import SettingsExampl from "./pages/Settings/SettingsExampl";
import UserAdd from "./pages/User/UserAdd";
import EmailHistoryList from "./pages/Templates/Email-Hstory/EmailHistoryList";
import ProjectsList from "./pages/Projects/ProjectsList";
import Info from "./pages/Info/Info";
import ProjectAdd from './pages/Projects/ProjectAdd';
import ProjectEdit from './pages/Projects/ProjectEdit';
import ProjectInfo from './pages/Projects/ProjectInfo';
import Guide from './pages/Guide/Guide';


const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'user/add', element: <UserAdd/>},
            {path: 'users', element: <UserList/>},
            {path: 'guide', element: <Guide/>},
            {path: 'projects', element: <ProjectsList/>},
            {path: 'projects/add', element: <ProjectAdd/>},
            {path: 'projects', element: <ProjectsList/>},
            {path: 'projects/edit/:id', element: <ProjectEdit/>},
            {path: 'projects/:id', element: <ProjectInfo/>},


            { path: 'info', element: <Info/> },

            { path: 'email-templates', element: <EmailTemplateList /> },
            { path: 'email-history', element: <EmailHistoryList /> },
            { path: 'email-templates/create', element: <EmailTemplateCreate /> },
            { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

            {path: 'logs', element: <Logs/>},
            {path: 'common-settings', element: <CommonSettings/>},
            {path: 'update-system', element: <UpdateSystem/>},


            {path: 'settings', element: <SettingsExampl/>},


            {path: '', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
