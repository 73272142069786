import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  TextField,
  TableFooter,
  TablePagination,
  Checkbox,
  Tab,
  Tabs,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/styles";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDelete, useGet, usePut } from "../../API/request";
import "../../styles/All.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BallTriangle } from "react-loader-spinner";
import UserListSkelet from "../../skeletons/UserListSkelet";
import { useConfirm } from "../../components/Confirm";
import CustomEventsTable from "./CustomEventsTable"

import ChartTab from "./ChartTab";
import StatsTab from "./StatsTab";


const ProjectInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut()
  const deleteU = useDelete()
  const { id } = useParams();
  const confirm = useConfirm()
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams({});

  const [project, setProject] = useState({
    id: '',
    name: '',
    apiKey: ''
  });
  const [events, setEvents] = useState([])

  const [tracker, setTracker] = useState([]);
  const [stats, setStats] = useState([]);
  const [eventsOpen, setEventsOpen] = useState(true);

  const [value, setValue] = useState('chart');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(10);



  const [queryParams, setQueryParams] = useState({
    fromDate: searchParams.get("fromDate") || "",
    toDate: searchParams.get("toDate") || "",
  });

  const loadTimeTracks = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `analytics-tags/${id}?page=${
      page
    }&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
      
          setTracker(resp.data.records.data);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setTracker([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };
  const loadProjectInfo = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `projects/${id}`

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
          setProject({
            id: resp.data.project.id,
            name: resp.data.project.name,
            apiKey: resp.data.project.api_key
          })
         
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setTracker([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };
  const loadEvents = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `analytics-tags/events/${id}`

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
         setEvents(resp.data.events)
         setIsLoaded(false);
         
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setCount(0);
        setIsDataLoading(false);
      })

  };

  const handleCheckImportant = (idEv, custom) => {
    setIsDataLoading(true);
    setIsLoaded(true);
  let endpoint
  
  if(custom) {
  endpoint = `custom-events/${idEv}`
  } else {
  endpoint = `analytics-tags/events/${idEv}`
  }
  
  
    putU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
        loadEvents()
        loadStats()
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };
  const loadStats = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `analytics-tags/events/${id}/stats`

    getU(endpoint)
      .then((resp) => {
        if (resp.status === "success") {
         setStats(resp.data.stats)
         setIsLoaded(false);
         
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        setCount(0);
        setIsDataLoading(false);
      })

  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (queryParams.fromDate === "") {
      searchParams.delete("fromDate");
      setSearchParams(searchParams);
    }
    if (queryParams.toDate === "") {
      searchParams.delete("toDate");
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadProjectInfo()
    loadEvents()
    loadStats()
  }, []);

  useEffect(() => {
    loadTimeTracks();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Сатитсика проекта</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button
            startIcon={<ChevronLeft />}
            onClick={() => navigate("/app/projects")}
          >
            Назад
          </Button>
          Мои проекты
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Мои проекты</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: "background.default", pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
              
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{project?.id || "---"}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Название</div>
                      <div className="text">{project?.name || "---"}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">API-ключ</div>
                      <div className="text">{project?.apiKey || "---"}</div>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <Box>
      <Box p={3} sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Button onClick={() => {setValue('chart')}}>Статистика по важным событиям</Button>
<Button onClick={() => {setValue('stats')}}>Отчёт</Button>
</Box>
{value === 'chart' &&
<ChartTab events={events} setEventsOpen={setEventsOpen} eventsOpen={eventsOpen} stats={stats} handleCheckImportant={handleCheckImportant}/> }
{value === 'stats' &&
<StatsTab  tracker={tracker} count={count} limit={limit} page={page} handleChangePage={handleChangePage} handleChangeLimit={handleChangeLimit} isDataLoading={isDataLoading}/>}

      </Box>

    </>
  );
};

export default ProjectInfo;



