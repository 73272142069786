import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import {BallTriangle} from "react-loader-spinner";
import '../../styles/All.css'


const ProjectsList = () => {
    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [queryParams, setQueryParams] = useState({
        username: searchParams.get("username") || '',
        email: searchParams.get("email") || '',
        id: searchParams.get("id") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.name !== '') {
            params.name = queryParams.name
        }
        if (queryParams.id !== '') {
            params.id = queryParams.id
        }
   
        console.log(Object.keys(params).length !== 0)
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `projects?page=${page + 1}&limit=${limit}`;

        if (queryParams.name) {
            endpoint += `&name=${searchParams.get("name")}`;
        }
        if (queryParams.id !== '') {
            endpoint += `&id=${searchParams.get("id")}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setProjects(resp.data.projects.data);
                    setCount(resp.data.totalCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                setProjects([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {

        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить проект?',
            onConfirm: () => {
                deleteU(`projects/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
    
        if (queryParams.id === '') {
            searchParams.delete("id")
            setSearchParams(searchParams);
        }
        if (queryParams.email === '') {
            searchParams.delete("name")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadUsers();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Мои проекты</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Мои проекты
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="add">
                                            <Button color="primary" variant="contained">
                                                Добавить проект
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1000}}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                          
                                                    <TextField
                                                        fullWidth
                                                        label="Id"
                                                        margin="normal"
                                                        name="id"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.id}
                                                        variant="outlined"
                                                        style={{width: '45%'}}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Название"
                                                        margin="normal"
                                                        name="name"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.name}
                                                        variant="outlined"
                                                        style={{width: '45%'}}
                                                    />
                                                    <Button
                                                        color="warning"
                                                        variant="contained"
                                                        onClick={handleFilterQueryParams}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Поиск
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Название
                                                            </TableCell>
                                                            <TableCell>
                                                                Ключ
                                                            </TableCell>
                                                     
                                                           
                                                            <TableCell>

</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {projects?.map((project) => (
                                                            <TableRow hover key={project.id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {project?.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {project?.name || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {project?.api_key || '---'}
                                                                </TableCell>
                                                           
                                                                <TableCell>
                                                                    <Box sx={{display: 'flex'}}>

                                                                        <Box sx={{display: 'flex'}}>
                                                                            <Link to={`${project.id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Инфо.
                                                                                </Button>
                                                                            </Link>
                                                                            <Box sx={{ml: 2}}>
                                                                                <Link
                                                                                    to={`edit/${project.id}`}
                                                                                    style={project?.role === "admin" ? {pointerEvents: 'none'} : {}}>
                                                                                    <Button color="success"
                                                                                            variant="contained"
                                                                                            disabled={project?.role === "admin"}
                                                                                    >
                                                                                        Редакт.
                                                                                    </Button>
                                                                                </Link>

                                                    

                                                                            </Box>
                                                                            <Box sx={{ml: 2}}>               
                                                            <Button
                                                                color="error"
                                                                variant="contained"
                                                                onClick={() => onDelete(project.id)}
                                                            >
                                                                Удалить
                                                            </Button>
                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>

                                                        
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Кол-во строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default ProjectsList;
