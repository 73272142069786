import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate, useParams} from 'react-router-dom';
import {useGet, usePut} from "../../API/request";
import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/core/Alert";
import { BallTriangle } from 'react-loader-spinner';
import EventFieldSelector from './EventField';

const ProjectEdit = () => {
    const {id} = useParams();
    const getU = useGet();
    const putU = usePut();
    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(true);
    const [values, setValues] = useState({
        name: '', 
    });

    const [apiKey, setApiKey] = useState('')
    const [initialKey, setInirialKey] = useState('')
    const [errors, setErrors] = useState({
        apiKey: false,
        name: false,
      
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [events, setEvents] = useState([])
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (apiKey.length < 25) {
            validComplete = false;
            formErrors.apiKey = false;
            showAlert('error', "Поле API-ключ должно содержать от 25 знаков")
        }

        setErrors(formErrors);
        return validComplete;
    };
    const generateKey = (e) => {
        e.preventDefault()
        const newApiKey = uuidv4();
        setApiKey(newApiKey)
    }


    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                name: values.name,       
            };


            if(initialKey !== apiKey) {
                data.api_key = apiKey
            }
          

            putU(`projects/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Проект успешно обновлён');
                    
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                    setSubmitDisabled(false);
                })
        }
    };


    const fetchEvents = async () => {
        getU(`custom-events/configured-fields/${id}`)
        .then((resp) => {
            if (resp.status === 'success') {
               setEvents(resp.data.fields)
               
            }
        })
        .catch(() => {
            showAlert('error', 'Произошла ошибка при загрузке данных, попробуйте перезайти');
        })
    }
    useEffect(() => {
        setIsLoaded(true)
        getU(`projects/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = {
                        name: resp.data.project.name,
                        apiKey: resp.data.project.api_key,
                    }
                    setInirialKey(resp.data.project.api_key)
                    setValues(data)
                    setIsLoaded(false)
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при загрузке данных, попробуйте перезайти');
            })
       
 fetchEvents()
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Редактирование проекта</title>
            </Helmet>
            <Box sx={{pt: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование проекта"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                            
                                    <TextField
                                    disabled={true}
                                        fullWidth
                                        label="API-ключ"
                                        margin="normal"
                                        name="apiKey"
                                        onChange={handleChange}
                                        type="apiKey"
                                        value={apiKey}
                                        variant="outlined"
                                        error={errors.apiKey}
                                    />
                                         <Button onClick={(e) => generateKey(e)}>Сгенерировать новый ключ</Button>
                              

                              

                                    <Alert severity={alert.type}
                                           style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>

                        <Card>
                                <CardHeader
                                    title="Редактирование кастомных событий"
                                />
                            <CardContent>
                                <EventFieldSelector  showAlert={showAlert} eventsData={events} fetchEvents={fetchEvents} setIsLoaded={setIsLoaded}/>
                             

                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ProjectEdit;
