import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate} from 'react-router-dom';
import {useGet, usePost, usePut} from "../../API/request";
import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/core/Alert";
import { v4 as uuidv4 } from 'uuid';

const ProjectAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();
    const [apiKey, setApiKey] = useState('')

    const [values, setValues] = useState({
        name: '',
    
     
    });
    const [errors, setErrors] = useState({
        apiKey: false,
        name: false,
      
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });


    const generateKey = (e) => {
        e.preventDefault()
        const newApiKey = uuidv4();
        setApiKey(newApiKey)
    }
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name === '') {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', "Поле Название не должно быть пустым")
        }
     
        if (apiKey === '') {
            validComplete = false;
            formErrors.apiKey = false;
            showAlert('error', "Поле API-ключ не должно быть пустым")
        }
        if (apiKey.length < 25) {
            validComplete = false;
            formErrors.apiKey = false;
            showAlert('error', "Поле API-ключ должно содержать от 25 знаков")
        }

        setErrors(formErrors);
        return validComplete;
    };



    const clearForm = () => {
        setValues({
            name: '',
    
        });

        setApiKey('')
    };

    const submit = async () => {
        if (validate()) {
    
            setSubmitDisabled(true);

            const data = {
                name: values.name,
                api_key: apiKey,
             
            };

            postU('projects', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Проект успешно добавлен');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                    setSubmitDisabled(false);
                })
        }
    };



    return (
        <>
            <Helmet>
                <title>Добавить новый проект</title>
            </Helmet>
            <Box sx={{pt: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Добавление нового проекта"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                    
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                            
                                    <TextField
                                        fullWidth
                                        label="API-ключ"
                                        disabled={true}
                                        margin="normal"
                                        name="apiKey"
                                        onChange={handleChange}
                                        type="apiKey"
                                        value={apiKey}
                                        variant="outlined"
                                        error={errors.apiKey}
                                    />
                                    <Button onClick={(e) => generateKey(e)}>Сгенерировать ключ</Button>
                              

                                    <Alert severity={alert.type}
                                           style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ProjectAdd;
