import {
    Box,
    Container,
    Card,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    TableFooter,
    TablePagination,
  } from "@material-ui/core";
  import "../../styles/All.css";
  import PerfectScrollbar from "react-perfect-scrollbar";
  import UserListSkelet from "../../skeletons/UserListSkelet";
  import CustomEventsTable from "./CustomEventsTable"
import { useParams } from "react-router";


const StatsTab = ({tracker, count, page, handleChangeLimit, handleChangePage, limit, isDataLoading}) => {
    const { id } = useParams();
    return ( 
          <Box>
        <Box className="headerWrapper">
          <Box className="headerTitle">Отчет</Box>
        </Box>
        <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
          <Container maxWidth={false}>
            {isDataLoading ? (
              <UserListSkelet />
            ) : (
              <>
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <PerfectScrollbar>
                      <Box sx={{ minWidth: 900 }}>
                        <Divider />
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: 80 }}>
                                Событие
                              </TableCell>
                              <TableCell>Дата и время</TableCell>
                              <TableCell>Место</TableCell>
                              <TableCell>ОС</TableCell>
                              <TableCell>Браузер</TableCell>
                              <TableCell>Устройство</TableCell>
                              <TableCell>Размер экрана</TableCell>
                              <TableCell>Страница</TableCell>
                              <TableCell>Результат</TableCell>
                              <TableCell>

</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {tracker?.map((track) => (
                              <TableRow hover key={track.id}>
                                <TableCell sx={{ width: 80 }}>
                                  {track.event_name}
                                </TableCell>
                                <TableCell>{track?.timestamp?.split('T')[0] + ' ' + track?.timestamp?.split('T')[1].slice(0, 8)}</TableCell>
                                <TableCell>
                                  {track.city}, {track.region}, {track.country}
                                </TableCell>
                                <TableCell>
                                {track.operating_system}
                                </TableCell>
                                <TableCell>
                                {track.browser}
                                </TableCell>
                                <TableCell>
                                {track.device_type}
                                </TableCell>
                                <TableCell>
                                {track.screen_resolution}
                                </TableCell>
                                <TableCell>
                                {track.page_title}
                                </TableCell>
                                <TableCell>
                                {track.event_value}
                                </TableCell>
                                {/* <TableCell sx={{width: 100}}>
                                                            <Button
                                                                color="error"
                                                                variant="contained"
                                                                onClick={() => deleteTrackingInfo(track.id)}
                                                            >
                                                                Удалить
                                                            </Button>
                                                        </TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                labelRowsPerPage={
                                  <span>Кол-во строк на странице:</span>
                                }
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                colSpan={7}
                                count={count}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeLimit}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                  </Card>
                </Box>
              </>
            )}

            <CustomEventsTable id={id}/>
          </Container>
        </Box>
      </Box>)
}



export default StatsTab