import React from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Card,
    CardContent,
} from '@material-ui/core';
import '../../styles/All.css'
import img1 from './1.jpg'
import img2 from './2.jpg'
import img3 from './3.jpg'

import img5 from './5.png'

import img6 from './6.jpg'

const Guide = () => {


    return (
<>
<Helmet>
                <title>Инструкция по интеграции</title>
           
            </Helmet>
            <Card style={{
                        overflowY: "scroll"
                    }}>
                    <CardContent style={{
                        overflowY: "scroll"
                    }}>
                    <Box className="headerTitle">
                    Инструкция по интеграции
                </Box>
                <p style={{
                    padding: '10px'
                }}>Все эндпоинты доступны по: <a href='https://cybertracker.api.demowts.ru/api-docs/' target='_blank'>https://cybertracker.api.demowts.ru/api-docs/</a></p>
                
                <p style={{
                    padding: '10px'
                }}>Для интеграции приложения недобходимо выполнить следующие шаги:</p>
            <ol style={{
                padding: '20px'
            }}>
              <li style={{padding: '10px'}}>В админ.панеле добавляем проект </li>  
              <img src={img1} className='imgGuide'></img>
              <li style={{padding: '10px'}}>Необходимо создать ключ, который затем будет использован в интеграцииприложения. Данный ключ должен быть не менее 25 знаков. </li>  
              <img src={img2} className='imgGuide'></img>
              <li style={{padding: '10px'}}>Для сохранения статистики необходимо сделать POST-запрос на  '/api/analytics-tags' и созданный ключ указываем в хедаре как apikey, например </li>  
              <img src={img3} className='imgGuide'></img>
              <li style={{padding: '10px'}}>Тело запроса </li>  
              <img src={img5} className='imgGuide'></img>
              <li style={{padding: '10px'}}>Далее информация о запросах будет доступна в разделе мои проекты - Инфо </li> 
            </ol>
                      
                    </CardContent>
                    <CardContent style={{
                        overflowY: "scroll"
                    }}>

                    <Box className="headerTitle">
                    Инструкция по добавлению кастомных источников
                </Box>
                <ol style={{
                padding: '20px'
            }}>
<p>Если это новый проект, то</p>
                <li style={{padding: '10px'}}>В админ.панеле добавляем проект </li>  
                <li style={{padding: '10px'}}>Необходимо создать ключ, который затем будет использован в интеграцииприложения. Данный ключ должен быть не менее 25 знаков. </li> 

                <p>Если проект уже существует, то добавляем API ключ в запросы</p>
        
                <li style={{padding: '10px'}}>Для сохранения статистики неодходимо сделать POST-запрос на 'api/custom-events'</li>  
                <li style={{padding: '10px'}}>Название полей должны совпадать с теми, что есть в админке. Если поля не совпадают - запрос вернет ошибку.</li>  
                <img src={img6} className='imgGuide'></img>
                <li style={{padding: '10px'}}>Далее информация о запросах будет доступна в разделе мои проекты - Инфо (таблица с кастомными источниками) </li> 
                
                </ol>
                </CardContent></Card>
</>
    );
};

export default Guide;
