import React, { useState } from 'react';
import {
  Select, MenuItem, TextField, IconButton, Button, Box
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useDelete, usePost } from '../../API/request';

const EventFieldSelector = ({showAlert, eventsData, fetchEvents }) => {
  const {id} = useParams();
  const postU = usePost();
  const deleteU = useDelete();
  const [selectedEvent, setSelectedEvent] = useState('');
  const [fields, setFields] = useState([]);
  const [isAddingNewEvent, setIsAddingNewEvent] = useState(false);
  const [newEventName, setNewEventName] = useState('');

  const uniqueEvents = eventsData.reduce((acc, current) => {
    const eventExists = acc.find(event => event.event_name === current.event_name);
    if (!eventExists) {
      acc.push(current);
    }
    return acc;
  }, []);


  
 
  const handleEventChange = (event) => {
    const selectedEventName = event.target.value;
    setSelectedEvent(selectedEventName);

    const fieldsArr = eventsData.filter(ev => ev.event_name === selectedEventName);
    setFields([...fieldsArr]);
    setIsAddingNewEvent(false); 
  };

  const handleRemoveField = (id, index) => {
    setFields(fields.filter((_, i) => i !== index));
    if(id) {
        deleteU(`custom-events/configured-fields/${id}`)
        .then((resp) => {
            if (resp.status === 'success') {
                showAlert('success', 'Поле было успешно удалено');
            } else {
                showAlert('error', 'Ошибка');
            }
        })
        .catch((err) => {
            showAlert('error', err.response.data.message);
        });
    }
  };

  const handleSave = () => {

    let newFields = [];

    const copyFields = [...fields];
    copyFields.forEach(field => {
        if (typeof field === 'string') {
            newFields.push(field);
        }
    });

    const body = {
        website_id: id, 
        event_name: isAddingNewEvent ? newEventName : fields[0]?.event_name,
        fields: newFields
    };


    postU(`custom-events/configured-fields`, body)
    .then((resp) => {
        if (resp.status === 'success') {
            fetchEvents()
            showAlert('success', 'Проект успешно обновлён');
          
           
        } else {
            showAlert('error', 'Ошибка');
        }

      
      
    })
    .catch((err) => {
        showAlert('error', err.response.data.message);
    });
  };

  const handleAddField = () => {
    setFields([...fields, '']);
  };

  const handleFieldChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = value;
    setFields(updatedFields);
  };

  const handleAddNewEvent = () => {
    setIsAddingNewEvent(true);
    setSelectedEvent('');
    setFields([]); 
  };

  return (
    <Box>
    
      {isAddingNewEvent ? (
        <TextField
          fullWidth
          label="Введите название события"
          value={newEventName}
          onChange={(e) => setNewEventName(e.target.value)}
        />
      ) : (
        <Box display="flex" alignItems="center">
          <Select
            value={selectedEvent}
            onChange={handleEventChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>Выберите событие</MenuItem>
            {uniqueEvents.map((event) => (
              <MenuItem key={event.id} value={event.event_name}>
                {event.event_name}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleAddNewEvent} sx={{ ml: 2 }} variant="contained">
            Добавить событие
          </Button>
        </Box>
      )}


      <Box mt={2}>
        {fields && fields.map((field, index) => (
          <Box display="flex" alignItems="center" mb={1} key={index}>
            <TextField
              fullWidth
              disabled={field.id}
              value={field.field_name || field}  
              variant="outlined"
              onChange={(e) => handleFieldChange(index, e.target.value)}
            />
            <IconButton onClick={() => handleRemoveField(field.id, index)} color="secondary">
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

   
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleAddField}>
          Добавить поле
        </Button>
        <Button sx={{ mx: 2 }} variant="contained" color="primary" onClick={handleSave}>
          Сохранить
        </Button>
      </Box>
    </Box>
  );
};

export default EventFieldSelector;
