import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Card,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableFooter,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { useDelete, useGet, usePut } from "../../API/request";
import { Loader } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
const CustomEventsTable = ({id}) => {
    const getU = useGet();
    const [isLoaded, setIsLoaded] = useState(false);
    const [custom, setCustom] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(10);
    useEffect(() => {
        loadCustom()
      }, [page, limit]);
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
      };
    
    const loadCustom = () => {    
        let endpoint = `custom-events/${id}?page=${
          page
        }&limit=${limit}`;
        getU(endpoint)
          .then((resp) => {
            if (resp.status === "success") {
             setCustom(resp.data.events)
             setIsLoaded(true);
             setCount(resp.data.currentCount || 0);
             
            }
           
          })
          .catch((err) => {
            setCount(resp.data.currentCount || 0);
         
          })
    
      };

if(!isLoaded) {
  return <Loader />
}

return (
    <Box>
        <Box className="headerWrapper">
          <Box className="headerTitle">Отчет по кастомным источникам</Box>
        </Box>
        <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
          <Container maxWidth={false}>
            {!isLoaded ? (
              <UserListSkelet />
            ) : (
              <>
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <PerfectScrollbar>
                      <Box sx={{ minWidth: 900 }}>
                        <Divider />
                        <Table>
                  
                        <>
                          <TableHead>
                      
                            <TableRow>

                              <TableCell>
                                Событие
                              </TableCell>
                              <TableCell>Дата и время</TableCell>

                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {custom?.map((track) => (                            
 <TableRow hover key={track.id}>

  <TableCell>
    {track.name}
  </TableCell>

  <TableCell>{track?.timestamp?.split('T')[0] + ' ' + track?.timestamp?.split('T')[1].slice(0, 8)}</TableCell>
  
  {track.fields.map(val => 
     <TableCell key={val.id}>

  {val.name}

  <Typography>
  {val.value}
  </Typography>

  </TableCell>)}
  </TableRow>
                            
                            ))}
                          </TableBody>
             
                          </>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                labelRowsPerPage={
                                  <span>Кол-во строк на странице:</span>
                                }
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                colSpan={7}
                                count={count}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeLimit}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                  </Card>
                </Box>
              </>
            )}
          </Container>
        </Box>
      </Box>)
}


export default CustomEventsTable